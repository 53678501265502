import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../layouts/Layout"
import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"
import Pricing from "../components/servicios/Pricing"

import Button from "../components/Button"


const ServiciosPage = ({ data }) => (
  <Layout>
    <SiteMetadata title="Servicios" description="Logra un objetivo sostenible a largo plazo y a la vez crea una relación sana con su alimentación. Consulta de seguimiento, consulta inicial, plan Más verde o consulta empresarial" />
    <Pricing />
    <div className="bg-white">
      <div className="container pb-12 lg:py-16">
        <div className="flex flex-wrap items-center">
        <h1 className="text-lg leading-tight font-bold text-gray-900 text-center md:text-center sm:text-xl md:text-2xl mb-4 md:mb-12">
            Atrévete a probar una alimentación 100% personalizada que beneficie tu cuerpo, mente y todo tu alrededor.
            </h1>
          <div className="md:w-full lg:w-2/3">
            <ul className="my-6 list-disc w-full">
              <li className="font-light text-base sm:text-lg md:text-lg pt-4 md:pt-8 ml-8 mr-0 md:mr-24 text-green-600"><div className="text-gray-900"> Te enseñaré a crear opciones para llevar una alimentación basada en plantas. Haciendo una prioridad en el consumo de alimentos nutritivos para tu mente, cuerpo y alma.</div></li>
              <li className="font-light text-base sm:text-lg md:text-lg pt-4 md:pt-8 ml-8 mr-0 md:mr-24 text-green-600"><div className="text-gray-900"> No hay restricciones ni dietas intensas, es crear un estilo de vida equilibrado con hábitos sostenibles.</div></li>
              <li className="font-light text-base sm:text-lg md:text-lg pt-4 md:pt-8 ml-8 mr-0 md:mr-24 text-green-600"><div className="text-gray-900"> Trabajaré en descubrir la raíz de cualquier desequilibrio, te mostraré como nutrirte intuitivamente y desvincularte de lo que te mantenga en batalla con la comida.</div></li>
              <li className="font-light text-base sm:text-lg md:text-lg pt-4 md:pt-8 ml-8 mr-0 md:mr-24 text-green-600"><div className="text-gray-900"> Te guiaré a consumir alimentos de una forma que no solo beneficie a tu cuerpo, si no que también sea sostenibles con el medio ambiente.</div></li>
              <a 
                  href="https://koalendar.com/e/luzamira-consulta-nutricional" 
                  className="flex justify-center md:justify-start mt-4 md:mt-8" 
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                  <Button alt="Agenda una cita">Agenda una cita</Button>
              </a>
            </ul>
          </div>
          <Img
            fluid={data.Author.childImageSharp.fluid}
            alt="Luz Amira"
            className="w-48 h-48 md:w-1/3 lg:w-1/3 md:h-auto mx-auto rounded-md"
          /> 
        </div>
      </div>
    </div>
    <Newsletter />
  </Layout>
)

export default ServiciosPage


export const query = graphql`
  query {
    Author: file(relativePath: { eq: "author.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 99) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }      
  }
`
