import React from "react"
import { Link } from "gatsby"
import { FaCheck } from "react-icons/fa"

const Pricing = () => (

<div className="bg-white">
    <div className="container py-6 lg:py-16">
        <h1 className="text-xl mb-4 leading-tight font-bold text-center text-gray-900 sm:mb-3 sm:text-2xl md:text-4xl lg:text-6xl">Mis Servicios</h1>
        <h2 className="text-base mb-4 leading-normal font-light text-center text-gray-900 sm:mb-6 sm:text-lg md:text-xl lg:text-2xl">Obtiene recomendaciones personalizadas para todas las necesidades de salud nutricional.</h2>
        <div className="mt-6">
            <ul className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 grid-rows-1 grid-flow-row ">
                <li className="flex justify-center">
                    <section className="shadow-lg border-t-2 border-gray-50 mx-2 bg-white rounded-lg w-full">
                        <div className="py-2 px-6 bg-white rounded-t-lg text-center">
                            <p className="">&nbsp;</p>
                        </div>
                        <div className="px-6 pb-6">
                            <h1 className="text-xl mt-6  font-bold text-gray-900 md:text-lg md:mt-6 lg:text-xl lg-mt-6">Discovery call</h1>
                            <h2 className="text-base mt-2 font-book text-gray-600 md:text-lg md:mt-3 lg:text-lg lg-mt-4">30 minutos</h2>
                            <h1 className="text-base mt-6 font-light text-gray-600 md:text-base md:mt-8 lg:text-lg lg-mt-10"><span className="text-2xl font-bold text-gray-900 md:text-2xl lg:text-4xl">Gratis</span></h1>
                            <a 
                                className="mt-4 flex justify-center md:mt-6 lg:mt-8 w-full px-5 py-3 font-bold leading-snug border-2 border-green-800 text-base rounded-md text-green-800 bg-white hover:bg-green-800 hover:text-white focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                                href="https://koalendar.com/e/luzamira-discovery-call"
                                target="_blank"
                                rel="noopener noreferrer"
                                alt="Lo quiero" >
                                ¡LO QUIERO!
                            </a> 
                        </div>
                        <div className="px-6 mb-6">
                            <h1 className="mt-4 font-book text-base md:mt-0">Incluye:</h1>
                            <div className="mt-6">
                                <ul className="grid grid-cols-6 grid-rows-8 grid-flow-row">
                                    <li className="flex justify-center mt-2"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                                    <li className="font-light text-base col-span-5 mt-2">Escuchar tu historia y brindarte  consejos por dónde comenzar.</li>
                                    <li className="flex justify-center mt-2"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                                    <li className="font-light text-base col-span-5 mt-2">Resolver tus dudas antes de iniciar consultas 1 a 1.</li>
                                </ul>
                            </div>
                        </div> 
                    </section>
                </li>
                <li className="flex justify-center bg-white">
                    <section className="shadow-lg border-t-2 border-gray-50 mx-2 bg-white rounded-lg w-full">
                        <div className="py-2 px-6 bg-white rounded-t-lg text-center">
                            <p className="">&nbsp;</p>
                        </div>
                        <div className="px-6 pb-6">
                            <h1 className="text-xl mt-6  font-bold text-gray-900 md:text-lg md:mt-6 lg:text-xl lg-mt-6">Consulta nutricional</h1>
                            <h2 className="text-base mt-2 font-book text-gray-600 md:text-lg md:mt-3 lg:text-lg lg-mt-4">60 minutos</h2>
                            <h1 className="text-base mt-6 font-light text-gray-600 md:text-base md:mt-8 lg:text-lg lg-mt-10"><span className="text-2xl font-bold text-gray-900 md:text-2xl lg:text-4xl">$70</span> USD</h1>
                            <a 
                                className="mt-4 flex justify-center md:mt-6 lg:mt-8 w-full px-5 py-3 font-bold leading-snug border-2 border-green-800 text-base rounded-md text-green-800 bg-white hover:bg-green-800 hover:text-white focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                                href="https://koalendar.com/e/luzamira-consulta-nutricional"
                                target="_blank"
                                rel="noopener noreferrer"
                                alt="Lo quiero" >
                                ¡LO QUIERO!
                            </a>    
                        </div>
                        <div className="px-6 mb-6">
                            <h1 className="mt-4 font-book text-base md:mt-0">Incluye:</h1>
                            <div className="mt-6">
                                <ul className="grid grid-cols-6 grid-rows-8 grid-flow-row">
                                    <li className="flex justify-center mt-2"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                                    <li className="font-light text-base col-span-5 mt-2">Consulta de 60 minutos.</li>
                                    <li className="flex justify-center mt-2"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                                    <li className="font-light text-base col-span-5 mt-2">Evaluación de composición corporal.</li>
                                    <li className="flex justify-center mt-2"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                                    <li className="font-light text-base col-span-5 mt-2">Revisión de exámenes bioquímicos.</li>
                                    <li className="flex justify-center mt-2"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                                    <li className="font-light text-base col-span-5 mt-2">Recomendaciones nutricionales.</li>
                                    <li className="flex justify-center mt-2"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                                    <li className="font-light text-base col-span-5 mt-2">PDF con plan de alimentación, suplementación, recomendaciones y guía de supermercado.</li>                                    
                                </ul>
                            </div>
                        </div> 
                    </section>
                </li>
                <li className="flex justify-center bg-gray-50">
                    <section className="shadow-lg bg-white rounded-b-lg w-full">
                        <div className="py-2 px-6 bg-green-200 rounded-t-lg text-center">
                            <p className="text-tiny font-book text-gray-900 md:text-base"><span className="text-tiny line-through font-book text-gray-900 md:text-base">$560</span> Ahorras $60 USD</p>
                        </div>
                        <div className="px-6 pb-6">
                            <h1 className="text-xl mt-6  font-bold text-gray-900 md:text-lg md:mt-6 lg:text-xl lg-mt-6">Programa Más verde</h1>
                            <h2 className="text-base mt-2 font-book text-gray-600 md:text-lg md:mt-3 lg:text-lg lg-mt-4">6 meses </h2>
                            <h1 className="text-base mt-6 font-light text-gray-600 md:text-base md:mt-8 lg:text-lg lg-mt-10"><span className="text-2xl font-bold text-gray-900 md:text-2xl lg:text-4xl">$500</span> USD</h1>
                            <a 
                                className="mt-4 flex justify-center md:mt-6 lg:mt-8 w-full px-5 py-3 font-bold leading-snug border-2 border-transparent text-base rounded-md text-white bg-green-800 hover:bg-green-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                                href="https://koalendar.com/e/luzamira-mas-verde"
                                target="_blank"
                                rel="noopener noreferrer"
                                alt="Lo quiero" >
                                ¡LO QUIERO!
                            </a> 
                        </div>
                        <div className="px-6 mb-6">
                            <h1 className="mt-4 font-book text-base md:mt-0">Incluye:</h1>
                            <div className="mt-6">
                                <ul className="grid grid-cols-6 grid-rows-8 grid-flow-row">
                                    <li className="flex justify-center mt-2"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                                    <li className="font-light text-base col-span-5 mt-2">Consulta inicial.</li>
                                    <li className="flex justify-center mt-2"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                                    <li className="font-light text-base col-span-5 mt-2">7 consultas de seguimiento.</li>
                                    <li className="flex justify-center mt-2"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                                    <li className="font-light text-base col-span-5 mt-2">Evaluación de composición corporal.</li>
                                    <li className="flex justify-center mt-2"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                                    <li className="font-light text-base col-span-5 mt-2">Revisión de exámenes bioquímicos.</li>
                                    <li className="flex justify-center mt-2"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                                    <li className="font-light text-base col-span-5 mt-2">PDF con plan de alimentación, suplementación, recomendaciones y guía de supermercado.</li>
                                    <li className="flex justify-center mt-2"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                                    <li className="font-light text-base col-span-5 mt-2">Cambios o ajustes en el plan de Alimentación.</li>
                                    <li className="flex justify-center mt-2"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                                    <li className="font-light text-base col-span-5 mt-2">Recetario.</li>
                                </ul>
                            </div>
                        </div> 
                    </section>
                </li>
                <li className="flex justify-center bg-gray-50">
                    <section className="shadow-lg border-t-2 border-gray-50 bg-white rounded-lg w-full">
                        <div className="py-2 px-6 bg-white rounded-t-lg text-center">
                            <p className="">&nbsp;</p>
                        </div>
                        <div className="px-6 pb-6">
                            <h1 className="text-xl mt-6  font-bold text-gray-900 md:text-lg md:mt-6 lg:text-xl lg-mt-6">Empresarial</h1>
                            <h2 className="text-base mt-2 font-book text-gray-600 md:text-lg md:mt-3 lg:text-lg lg-mt-4">Organizaciones o grupos</h2>
                            <h1 className="text-2xl font-bold text-gray-900 md:text-2xl lg:text-4xl mt-6 md:mt-8">Contacto</h1>
                            <Link 
                                to="/contacto"
                                alt="contacto"
                                className="mt-4 flex justify-center md:mt-6 lg:mt-8 w-full px-5 py-3 font-bold leading-snug border-2 border-green-800 text-base rounded-md text-green-800 bg-white hover:bg-green-800 hover:text-white focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                                >
                                Contáctame
                            </Link>
                        </div>
                        <div className="px-6 mb-6">
                            <h1 className="mt-4 font-book text-base md:mt-0">Incluye:</h1>
                            <div className="mt-6">
                                <ul className="grid grid-cols-6 grid-rows-8 grid-flow-row">
                                    <li className="flex justify-center mt-2"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                                    <li className="font-light text-base col-span-5 mt-2">Sesiones de charlas nutricionales.</li>
                                    <li className="flex justify-center mt-2"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                                    <li className="font-light text-base col-span-5 mt-2">Evaluaciones de composición corporal.</li>
                                    <li className="flex justify-center mt-2"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                                    <li className="font-light text-base col-span-5 mt-2">Planes de alimentación.</li>
                                    <li className="flex justify-center mt-2"><FaCheck className="h-5 w-auto text-green-600 fill-current"/></li>
                                    <li className="font-light text-base col-span-5 mt-2">Recomendaciones personalizadas.</li>
                                </ul>
                            </div>
                        </div> 
                    </section>
                </li>
            </ul>
        </div>
    </div>
</div>
)

export default Pricing